<template>
    <v-container fill-height>
        <v-layout justify-center wrap>
            <v-flex md12>
                <material-card title="Wellstar Data"
                               text="Show wellstar invoice and quote data">

                    <template :slot="'header_actions'">
                        <v-btn small fab rounded
                               v-tooltip="'Add new user'" color="white"
                               :to="'/c-panel/user/0'">
                            <v-icon color="primary">
                                mdi-plus
                            </v-icon>
                        </v-btn>
                    </template>


                    <template>
                        <cpanel-filter-data :original_url="filter_url"
                                            :fields_to_filter="filter_fields"
                                            @trigger-new-search="triggerSearch"
                        >
                        </cpanel-filter-data>
                        <v-data-table :headers="headers"
                                      :loading="loading"
                                      :loading-text="'Loading please wait'"
                                      :items="profiles">


                            <template v-slot:item.action="{ item }">
                                <v-btn small fab text
                                       v-if="typeof item.user.groups[0] !== 'undefined' && item.user.groups[0].id !== 1 && item.user.groups[0].id !== 2"
                                       uk-tooltip="title: Use As This User"
                                       @click="useAsUser(item)">
                                    <v-icon>mdi-check</v-icon>
                                </v-btn>
                                <v-btn small fab text
                                       uk-tooltip="title: Edit User"
                                       :to="'/c-panel/user/'+item.id">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>

                                <v-btn
                                        color="blue-grey darken-4"
                                        @click="update_state(1, item)"
                                        text
                                        fab
                                        v-show="item.state !== 1"
                                >
                                    <v-icon>
                                        mdi-account-plus
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                        color="blue-grey darken-4"
                                        @click="update_state(2, item)"
                                        text
                                        fab
                                        v-show="item.state === 1"
                                >
                                    <v-icon>
                                        mdi-account-off
                                    </v-icon>
                                </v-btn>


                            </template>

                        </v-data-table>
                    </template>
                </material-card>
            </v-flex>
        </v-layout>

    </v-container>
</template>

<script>
    export default {
        name: "profiles",
        data: () => ({
            // Loading data
            title: "profiles",
            init_url: '/admins/user-profiles-default/',
            load_url: undefined,
            filter_url: '/admins/user-profiles-default/',
            filter_fields: [
                {
                    label: 'Search',
                    type: 'text',
                    value: '',
                    table: 'username',
                    required: true,
                    rules: []
                },
                {
                    label: 'User Type',
                    type: 'select',
                    options: [
                        {
                            id: 0,
                            name: 'All'
                        },
                        {
                            id: 1,
                            name: 'Developer'
                        },
                        {
                            id: 2,
                            name: 'Admins'
                        },
                        {
                            id: 3,
                            name: 'Sourcer'
                        },
                        {
                            id: 6,
                            name: 'Factory User'
                        },
                        {
                            id: 9,
                            name: 'Sales Direct'
                        },
                        {
                            id: 11,
                            name: 'Sales Manager'
                        },
                        {
                            id: 13,
                            name: 'Partner'
                        },
                        {
                            id: 15,
                            name: 'Logistics'
                        },
                        {
                            id: 17,
                            name: 'Sourcing Manager'
                        },
                    ],
                    value: 0,
                    track_by: 'id',
                    show: 'name',
                    table: 'group',
                    required: true,
                    rules: []
                },
            ],

            // Table data
            profiles: [],
            headers: [
                {
                    text: 'ID',
                    align: 'center',
                    value: 'id',
                },
                {
                    text: 'First Name',
                    align: 'left',
                    value: 'user.first_name',
                },
                {
                    text: 'Username',
                    align: 'left',
                    value: 'user.username',
                },
                {
                    text: 'Group',
                    align: 'left',
                    value: 'user.groups[0].name',
                    custom: true
                },

                {
                    text: 'Actions',
                    align: 'center',
                    value: 'action',
                }
            ],

            // Behaviour variables
            user_groups: [],
            filter_results: [],
            loading:true,
        }),
        created() {
            this.load_url = this.init_url;
            this.load_profiles()
        },
        methods: {
            update_state(state, user)
            {
                console.log(user);
                this.$hpatch(this.$http.defaults.baseURL + '/admins/user-profiles-default/'+user.id+'/',{
                    state:state
                } ).then((response) => {
                    if(typeof response.data !== 'undefined')
                    {
                        user.state = state;
                        this.$notify({
                            title: 'Success:',
                            text: 'State changed successfully',
                            group: 'notifications',
                            position: 'top right'
                        });
                    }
                });
            },
            async load_profiles() {
                this.profiles = await this.$http.get(this.load_url)
                    .then(response => response.data.results)
                    .catch(error => {
                        console.log(error);
                    });
                this.loading = false;
            },

            triggerSearch(parsed_url) {
                //Changing load_url depending on what the search component triggers
                if (!!parsed_url) this.load_url = parsed_url;
                else this.load_url = this.init_url;

                this.profiles = [];
                this.load_profiles();
            },

            async useAsUser(user) {
                user = user.user;

                let login_data = await this.$http
                    .post('/admins/use-as-user/', user)
                    .then(response => response.data)
                    .catch(error => {
                        console.log(error);
                    });


                await this.$store.dispatch('loginAsUser', login_data);
                window.location = '/c-panel';
            }
        },
    }
</script>

<style scoped>

</style>
